import Banner from "./Banner";
import Information from "./Information";

const ContactPage = () => {
  return (
    <>
      <Banner />
      <Information />
    </>
  );
};

export default ContactPage;
