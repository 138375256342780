const SoftwareIcon = ({ width = 50, height = 50 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M12.58 3.12v.01c-.31 0-.62.09-.87.26c-.5.35-.56.93-.11 1.3L14.46 7l-8.73.03h-.01C5 7.03 4.3 7.5 4.16 8.1c-.16.61.34 1.12 1.1 1.12l4.43-.01l-7.93 6.09c-.76.57-.99 1.52-.52 2.12c.48.61 1.49.61 2.26 0l4.3-3.53s-.07.48-.06.76c0 .29.1.85.23 1.28c.29.93.78 1.78 1.46 2.53c.7.77 1.57 1.39 2.57 1.83c1.03.47 2.17.71 3.34.71c1.16 0 2.31-.25 3.35-.72c1-.44 1.86-1.07 2.56-1.84c.68-.75 1.17-1.61 1.46-2.53c.14-.47.23-.94.29-1.41c0-.47 0-.94-.06-1.41c-.13-.91-.44-1.77-.94-2.55c-.44-.71-1-1.34-1.69-1.87l-6.89-5.29a1.42 1.42 0 0 0-.84-.26m2.76 6.09c1.16 0 2.25.38 3.12 1.08c.44.36.79.78 1.04 1.25c.27.46.44 1.01.5 1.57c.04.56-.04 1.12-.23 1.66c-.2.54-.52 1.04-.95 1.49c-.89.9-2.13 1.42-3.48 1.42c-1.34 0-2.59-.51-3.48-1.41c-.43-.44-.75-.95-.95-1.49a3.9 3.9 0 0 1-.22-1.66c.05-.56.22-1.09.48-1.57c.26-.47.62-.89 1.06-1.25c.86-.71 1.96-1.09 3.11-1.09m.1 1.4c-.78 0-1.5.28-2.03.73c-.54.46-.91 1.1-.94 1.84c-.04.75.26 1.45.79 1.97c.54.53 1.32.85 2.18.85s1.63-.32 2.18-.85c.53-.52.83-1.22.79-1.97c-.04-.74-.41-1.38-.94-1.84c-.53-.45-1.25-.73-2.03-.73"
      />
    </svg>
  );
};

export default SoftwareIcon;
